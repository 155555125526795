<template lang="pug">
SingaButton.is-full-ghost.is-text-cta(@click="openSongRequestModal()" :class="blok.button_left ? 'is-justify-content-flex-start' : ''") {{ blok.label }}
</template>

<script setup lang="ts">
const { $oruga } = useNuxtApp()
const SongRequest = resolveComponent('ModalsSongRequest')

defineProps({
	blok: {
		required: true,
		type: Object
	}
})

const openSongRequestModal = () => {
	$oruga.modal.open({
		component: SongRequest,
		width: 768
	})
}
</script>

<style lang="sass" scoped>
.is-text-cta
	justify-content: flex-start
</style>
